import "./styles/userdash.css";
import { MdDashboardCustomize } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { FaShoppingCart } from "react-icons/fa";

const UserDashboard = () => {

  const tableData = [
    ['Row 1', 'Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
    ['Row 2', 'Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
    ['Row 3', 'Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
    ['Row 4', 'Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
    ['Row 5', 'Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
    ['Row 6', 'Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
    ['Row 7', 'Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
    ['Row 8', 'Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
  ];



  return (
    <>

      <div className="usercontainer">


        <div className='usercont1'>

          <div className="logo"><img src="/logo.jpeg" height={70} width={70} /></div>

          <div className="dash">
            Dashboard
          </div>

          <div className="userdashicons"><MdDashboardCustomize size={28} color="white" /></div>
          <div className="userdashicons"><MdDashboardCustomize size={28} color="white" /></div>
          <div className="userdashicons"><MdDashboardCustomize size={28} color="white" /></div>
          <div className="userdashicons"><MdDashboardCustomize size={28} color="white" /></div>

        </div>


        <div className="usercont2">

          <div className="usertopbar">
            <div>Divyanshu Rawat</div>

            <div className="userrighticons">
              <IoMdNotifications size={28} className="notifications" />
              <CgProfile size={28} className="profile" />
            </div>

          </div>


          <div className="threeboxes">

            <div className="box">
              <div className="usercart"><FaShoppingCart size={30} /></div>
              <div className="userorders">Orders</div>
              <div className="usernumbers">25</div>
            </div>

          </div>


          <div className="table-container">
            <table className="beautiful-table">
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={cellIndex}>{cellData}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          
        </div>

      </div>

    </>
  );
};

export default UserDashboard;