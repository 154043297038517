import Home from './Components/Home';
import Shop from './Components/Shop';
import Notes from './Components/Notes';
import SignupForm from './Components/Signup';
import UserDashboard from './Components/UserDashboard';
import SigninForm from './Components/Signin';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Router>     
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/product/product" element={<Notes />} />
        <Route exact path="/signup" element={<SignupForm />} />
        <Route exact path="/signin" element={<SigninForm />} />
        <Route exact path="/user" element={<UserDashboard />} />
      </Routes>
      
    </Router>
  );
};

export default App;
