import "./styles/NavFooter.css";
import { Link } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { BsInstagram } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <>

            <footer className="footerbackground" >
                <div className="footergrid">

                    <div className="footerbox1" >
                        <img src="/0000.webp" className="footerheading00"></img>
                        <div>Coding4U is a blog website where users can publish their articles related to programming or computer science field. It is designed to help individuals learn programming concepts, languages, and best practices. </div>


                        <div className="Footerlogos">
                            {/* scf */}
                        </div>


                    </div>



                    <div className="footerbox">
                        <div className="footerheading">Quick Links</div>
                        <ul>
                            <li> <Link to="#">Order </Link>  </li>
                            <li> <Link to="#">Privacy Policy </Link>  </li>
                            <li> <Link to="#">Terms & Conditions</Link>  </li>
                            <li> <Link to="#">Refund Policy </Link>  </li>
                            <li> <Link to="#">Support </Link>  </li>
                        </ul>
                    </div>



                    <div className="footerbox">
                        <div className="footerheading">Account</div>
                        <ul>
                            <li> <Link to="#">Register</Link>  </li>
                            <li> <Link to="#">Login </Link>  </li>
                            <li> <Link to="#">MyAccount </Link>  </li>
                            <li> <Link to="#">Downloads </Link>  </li>
                        </ul>
                    </div>





                    <div className="footerbox">

                        <div className="footerheading">Contact Us</div>
                        <ul>

                            <div className="mail">
                                <MdEmail size={25} />&nbsp; YourEmail@Gmail.com
                            </div>

                            <div className="social">
                                <ul>
                                    <li> <Link to="#"> <BsInstagram size={28} /> </Link></li>
                                    <li> <Link to="#">  <FaGithub size={28} /></Link></li>
                                    <li> <Link to="#">  <FaFacebook size={28} /></Link></li>
                                </ul>
                            </div>



                        </ul>
                    </div>


                </div>


                <div className="footercopyright">Copyright {currentYear} @ Home</div>

            </footer>


        </>

    )
}

export default Footer