import "./styles/Signup.css";
import { useState } from "react";
import Navbar from './Navbar';
import Footer from './Footer';
import { authenticate, isAuth, Signup } from "../actions/auth";
import { useNavigate } from 'react-router-dom';

const SignupForm = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        error: '',
        loading: false,
        message: '',
        showForm: true
    });


    const { name, email, password, error, loading, message, showForm } = values;


    const handleSubmit = e => {
        e.preventDefault();
        setValues({ ...values, loading: true, error: false });
        const user = { email, password, name };

        Signup(user).then(data => {
            if (data && data.error) {
                setValues({ ...values, error: data.error, loading: false });
            }
               
            else {
                authenticate(data, () => {
                    if (isAuth()) {
                      navigate('/user');
                    }
                  });
                }
                
        });
    };

    const handleChange = name => e => {
        setValues({ ...values, error: false, [name]: e.target.value });
    };

    const showLoading = () => (loading ? <div className="showLoading">Loading...</div> : '');
    const showError = () => (error ? <div className="showError">{error}</div> : '');
    const showMessage = () => (message ? <div className="showMessage">{message}</div> : '');



    const signupForm = () => {
        return (

            <>
                <div className="wrapper">
                    <h1 className="heading">New User ?</h1>
                    <p>Please create an account with us</p>

                    <br />

                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <input className="inputs" value={name} onChange={handleChange('name')} name="name" type="text" placeholder="Name" />
                        <input className="inputs" value={email} onChange={handleChange('email')} name="email" type="text" placeholder="Email" />
                        <input className="inputs" value={password} onChange={handleChange('password')} name="password" type="text" placeholder="Password" />

                        <button className="button">Signup</button>
                        <div className="notmember">
                            Already a member ? &nbsp; <a href="/signin"> Sign In</a>
                        </div>
                    </form>
                </div>
            </>

        )
    }



    return (
        <>
            <Navbar />
            <div className="backImg">
                <br />
                <br />
                {showError()}
                {showLoading()}
                {showMessage()}
                {showForm && signupForm()}
                <br /><br />
            </div>
            <Footer />
        </>
    )
}

export default SignupForm