import Navbar from './Navbar';
import Footer from './Footer';
import "./styles/Notes.css";
import { IoCartOutline } from "react-icons/io5";

const Notes = () => {
    return (
        <>
            <Navbar />


            <div className='notes-notesarea'>

                <div className='notes-topimg'>
                    <img className='notes-IMG'  src='/web.webp' />
                </div>

                <div className='notes-second'>
                    <h1> Python Handwritten Notes</h1>
                    <div className="notes-rupees">
                        <span className="cancel0">₹399.00</span>
                        <span className="actual0">₹200.00</span>
                    </div>
                    <div className="notes-star">⭐⭐⭐⭐⭐</div>


                    <div className='notes-text'>
                        <p>Unlock Python’s potential with our expertly crafted handwritten notes. Whether you’re a beginner or an experienced programmer, these notes will boost your Python skills.</p>

                        <h3>Benefits</h3>

                        <div>
                            <p> 1. Clear and Neat Writing</p>
                            <p> 2. Easiest Explanation</p>
                            <p> 3. Best for Exams Preparation</p>
                            <p> 4. Cover Almost Python Topics</p>
                        </div>

                        <li>Pages:115</li>
                        <li>Chapters: 18</li>
                        <li>Syllabus: Find Syllabus Below</li>

                    </div>

                    <div className='btndiv'>
                        <a>  <button>ADD TO Cart</button></a>
                        <a> <button>BUY NOW</button></a>
                    </div>

                    <div className='category'>Category: Coding Ebook</div>

                </div>

            </div>

            <h2 className='desc'>Description</h2>

            <div className='notes-descparas'>
                Syllabus Included in this Ebook👇


                Chapter 1: Introduction to Python

                What is Python?
                Features of Python
                Applications Of Python
                Python Installation
                First Python Program
                Chapter 2: Modules, Comment & Pip

                Modules in Python
                Three Main Types of Modules
                Comments in Python
                Types of Comments
                What is a pip?
                Chapter 3: Variables, Data Types Keywords & Operators

                Variables in Python
                Identifier in Python
                Data Types in Python
                Keywords in Python
                Operators in Python
                Chapter 4: List, Dictionary, Set, Tuples & Type Conversion

                Lists in Python
                Dictionary in Python
                Set in Python
                Tuple in Python
                Chapter 5: Flow Control

                Python Indentation
                If-Statement
                if-else Statement
                elif Statement
                Nested if Statement
                Chapter 6: Loops

                Pass Statement in Python
                While Loop in Python
                For Loop in Python
                For Loop Using range() Function
                Nested For Loop
                Chapter 7: Strings

                Python String
                Creating String in Python
                Strings indexing and splitting
                Deleting the String
                String Operations
                String Formatting
                Chapter 8: Functions

                Function in Python
                Creating a Function
                Function Calling
                Return Statement
                Arguments in Function
                Scope Of Variable
                Chapter 9: File Handling

                Introduction to file input/output
                Opening and Closing Files
                Reading and Writing Text Files
                Working with Binary Files
                Exception Handling in File Operations
                Chapter 10: Object Oriented Programming

                Introduction to OOP in Python
                Classes and Objects
                Constructor and Destructors
                Inheritance & Polymorphism
                Encapsulation and Data Hiding
                Method Overriding and Overloading
                Chapter 11: Exception Handling

                Introduction to Exception Handling
                Exception Handling Mechanism
                Handling Multiple Exception
                Customer Expectation
                Error Handling Strategies
                Chapter 12: Advanced Data Structure

                Lists
                Sets
                Dictionaries
                Stack and Queue (Using Lists)
                Chapter 13: Functional Programming

                Map, Filter & Reduce
                Lambda and Anonymous Functions
                Chapter 14: Working with Files and Directories

                Files i/o Operations (Binary Files)
                Directory Manipulation
                Working with CSV and JSON Files
                Chapter 15: Regular Expression

                Introduction to Regular Expression
                Pattern Matching with re-Module
                Common Regular Expression Pattern
                Chapter 16: Web Development with Python

                Introduction to Web Development
                Building Simple Web Applications with Flask and Django
                Chapter 17: Data Analysis and Visualisation

                Numpy and Pandas for Data Manipulation
                Matplotlib and Seaborn
                Chapter 18: Machine Learning and AI

                Introduction to Machine Learning
                Using Libraries like scikit Learn and Tesorflow
                Simple Machine Learning Example
            </div>



            <h2 className='desc'>Related Products</h2>


            <div className="notes-grid">

                <div className="notes-grid-item">
                    <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                        <div>Python Handwritten Notes</div></a>
                    <div className="shopstars">⭐⭐⭐⭐⭐</div>
                    <div className="rupees0">
                        <span className="cancel">₹399.00</span>
                        <span className="actual">₹200.00</span>
                    </div>
                    <a href="#" className="notes-cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                </div>



                <div className="notes-grid-item">
                    <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                        <div>Python Handwritten Notes</div></a>
                    <div className="shopstars">⭐⭐⭐⭐⭐</div>
                    <div className="rupees0">
                        <span className="cancel">₹399.00</span>
                        <span className="actual">₹200.00</span>
                    </div>
                    <a href="#" className="notes-cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                </div>



                <div className="notes-grid-item">
                    <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                        <div>Python Handwritten Notes</div></a>
                    <div className="shopstars">⭐⭐⭐⭐⭐</div>
                    <div className="rupees0">
                        <span className="cancel">₹399.00</span>
                        <span className="actual">₹200.00</span>
                    </div>
                    <a href="#" className="notes-cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                </div>



                <div className="notes-grid-item">
                    <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                        <div>Python Handwritten Notes</div></a>
                    <div className="shopstars">⭐⭐⭐⭐⭐</div>
                    <div className="rupees0">
                        <span className="cancel">₹399.00</span>
                        <span className="actual">₹200.00</span>
                    </div>
                    <a href="#" className="notes-cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                </div>
            </div>

            <Footer />
        </>
    )
}


export default Notes;