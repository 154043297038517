import "./styles/Home.css";
import { FaSearch } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImHappy2 } from "react-icons/im";
import Navbar from './Navbar';
import Footer from './Footer';

const Home = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };

    const images = [
        'https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        'https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        'https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        'https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        'https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        'https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',

    ];



    return (
        <>
            <Navbar />

            <div className="firstcomponent">
                <div className="firstcomponentchild">

                    <div className="child1">
                        <h1>Simplify Learning With Handwritten Notes!</h1>
                        <div className="searchbar"> <input type="search" className="search" placeholder="Search EBooks" /><FaSearch className="iconsearch" /></div>
                        <div className="browse"> <button className="topbtn"><a href="#">Browse All E Books </a></button><FaArrowAltCircleRight color="white" /></div>
                    </div>

                    <div className="child2"><img src="/girl.webp" className="girlImg" /></div>

                </div>
            </div>



            <div className="heading">  <h2>Most Popular Handwritten Ebooks</h2></div>
            <div className="heading">  <h2>Why Handwritten Notes are Best ?</h2></div>





            <h2 className="customerh2"> Free Coding E-Books</h2>

            <div className="download">
                <div>
                    <img src="/py.webp" />
                    <h2>Complete Python Ebook</h2>
                    <button><a href="#">Download From Telegram</a></button>
                </div>


                <div>
                    <img src="/py.webp" />
                    <h2>All Programing Language</h2>
                    <button><a href="#">Download From Telegram</a></button>
                </div>

            </div>



            <h2 className="customerh2">Our Happy Customers</h2>

            <div style={{ textAlign: "center", marginBottom: "20px" }}>   <ImHappy2 size={40} color="#1167FF" />  </div>

            {/* <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} >
                        <img src={image} alt={`Slide ${index}`} />
                    </div>
                ))}
            </Slider> */}
















            <div className="community">

                <h2 className="communityh2">Strong Coders Community</h2>

                <div className="column">

                    <div className="col1">
                        <h2>180K+ Instagram Family</h2>
                        <img src="/followers1.webp" />
                    </div>

                    <div className="col2">
                        <h2>80K+ Telegram Family</h2>
                        <img src="/followers2.webp" />
                    </div>

                </div>
            </div>



            <div className="last1">

                <h2>Be the Topper in Your Exams</h2>
                <p>Welcome to our digital library, a place where you can explore a wealth of coding knowledge. We’ve carefully selected eBooks to help you learn and grow, whether you’re just starting out or have experience. Our goal is to guide you on your path to becoming a skilled coder, step by step.</p>
                <button>Shop Now</button>


            </div>





            <div className="best">

                <div className="bestchild1">
                    <h3>Revision Efficiency​</h3>
                    <p>Reviewing handwritten notes is efficient for exam preparation​</p>
                    <h3>Enhanced Focus</h3>
                    <p>Writing notes promotes better engagement and concentration</p>
                    <h3>Visual Learning Aid</h3>
                    <p>Handwritten notes add visual appeal and aid memory retention</p>
                </div>


                <div className="boyImgdiv"><img src="/boy.webp" className="boy" /></div>



                <div className="bestchild2">
                    <h3>Long-term Retention</h3>
                    <p>Reviewing handwritten notes is efficient for exam preparation</p>
                    <h3>Student-Friendly​</h3>
                    <p>Reviewing handwritten notes is efficient for exam preparation</p>
                    <h3>Visual Clarity</h3>
                    <p>Handwritten notes provide clear and visually engaging explanations, enhancing understanding.</p>
                </div>

            </div>



            <div className="last">
                <h2>1000+ Peoples Joined in Last 30 DAYS</h2>
                <button>Shop Now</button>
            </div>




            <Footer/>


        </>
    )
}

export default Home;