import "./styles/Shop.css";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";
import ReactSlider from "react-slider";
import { IoCartOutline } from "react-icons/io5";
import Navbar from './Navbar';
import Footer from './Footer';

const Shop = () => {


    const [values, setValues] = useState([0, 100]);
    const handleChange = (newValues) => setValues(newValues);


    return (
        <>
        <Navbar/>

            <div className="shop">


                <div className="shopfirstdiv">
                    <div className="searchbar"> <input type="search" className="search" placeholder="Search EBooks" /><FaSearch className="iconsearch" /></div>

                    <ReactSlider
                        className="slider"
                        defaultValue={[0, 500]}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        pearling
                        minDistance={0}
                        max={500}
                        onChange={handleChange}
                    />

                    <br />

                    <button className="filterbtn">Filter</button> &nbsp; &nbsp;
                    <span className="range">  ₹{values[0]} – ₹{values[1]}</span>

                    <div className="related">Top Rated Products</div>

                    <div className="toprated">
                        <div>
                            <img src="/web.webp" />
                        </div>

                        <div className="second">
                            <div className="title"><a href="#">Data Structure & Algorithm Handwritten Notes</a></div>
                            <div className="star">⭐⭐⭐⭐⭐</div>
                            <div className="rupees">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                        </div>
                    </div>


                    <div className="toprated">
                        <div>
                            <img src="/web.webp" />
                        </div>

                        <div className="second">
                            <div className="title"><a href="#">Data Structure & Algorithm Handwritten Notes</a></div>
                            <div className="star">⭐⭐⭐⭐⭐</div>
                            <div className="rupees">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                        </div>
                    </div>


                    <div className="toprated">
                        <div>
                            <img src="/web.webp" />
                        </div>

                        <div className="second">
                            <div className="title"><a href="#">Data Structure & Algorithm Handwritten Notes</a></div>
                            <div className="star">⭐⭐⭐⭐⭐</div>
                            <div className="rupees">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                        </div>
                    </div>



                    <div className="toprated">
                        <div>
                            <img src="/web.webp" />
                        </div>

                        <div className="second">
                            <div className="title"><a href="#">Data Structure & Algorithm Handwritten Notes</a></div>
                            <div className="star">⭐⭐⭐⭐⭐</div>
                            <div className="rupees">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                        </div>
                    </div>



                    <div className="toprated">
                        <div>
                            <img src="/web.webp" />
                        </div>

                        <div className="second">
                            <div className="title"><a href="#">Data Structure & Algorithm Handwritten Notes</a></div>
                            <div className="star">⭐⭐⭐⭐⭐</div>
                            <div className="rupees">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                        </div>
                    </div>


                </div>



                <div className="shopseconddiv">


                    <div class="container">
                        <div class="left">
                            Showing all 11 results
                        </div>

                        <div class="right">
                            <select id="categorySelect" className="inputcat">
                                <option value="category1">Sort By Popularity</option>
                                <option value="category2">Sort By Average rating</option>
                                <option value="category3">Sort By latest</option>
                                <option value="category4">Sort by price:low to high</option>
                                <option value="category5">Sort by price:high to low</option>
                            </select>
                        </div>
                    </div>




                    <div className="grid">



                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>



                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>



                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>



                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>



                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>


                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>



                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>



                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>
                    

                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>


                        <div className="grid-item">
                          <a href="#"> <div><img className="listimg" src="/web.webp" /></div>
                            <div>Python Handwritten Notes</div></a> 
                            <div className="shopstars">⭐⭐⭐⭐⭐</div>
                            <div className="rupees0">
                                <span className="cancel">₹399.00</span>
                                <span className="actual">₹200.00</span>
                            </div>
                            <a href="#" className="cart">  <IoCartOutline size={24} />&nbsp; Add To Cart</a>
                        </div>


                    </div>
                </div>


            </div>

<Footer/>


        </>
    )
}

export default Shop;