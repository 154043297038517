import axios from "axios";
import { API } from "../config";
import cookie from 'js-cookie';

export const Signup = async (user) => {
  try {
    const response = await axios.post(`${API}/api/user/signup`, user, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const Signin = async (user) => {
  try {
    const response = await axios.post(`${API}/api/user/login`, user, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};



export const setCookie = (key, value) => {
  if (typeof window !== 'undefined') {
    cookie.set(key, value, {

    });
  }
};

export const removeCookie = key => {
  if (typeof window !== 'undefined') {
    cookie.remove(key, {

    });
  }
};


export const getCookie = key => {
  if (typeof window !== 'undefined') {
    return cookie.get(key);
  }
};


export const authenticate = (data, next) => {
  setCookie('token', data.token);
  next();
};


export const isAuth = () => {
  if (typeof window !== 'undefined') {
    const token = getCookie('token');
    
    if (token != 'undefined') {
      console.log(token);
      return true;
        }
    else {
      console.log("Token Not Found");
      return false;
    }
  }
};