import "./styles/Signup.css";
import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate, isAuth, Signin } from "../actions/auth";
import Navbar from './Navbar';
import Footer from './Footer';

const SigninForm = () => {

    const navigate = useNavigate();

    useEffect(() => {
        isAuth() && navigate('/user');
      }, []);




    const [values, setValues] = useState({
        email: '',
        password: '',
        error: '',
        loading: false,
        message: '',
        showForm: true
    });

    const { email, password, error, loading, message, showForm } = values;



    const handleSubmit = e => {
        e.preventDefault();
        setValues({ ...values, loading: true, error: false });
        const user = { email, password };

        Signin(user).then(data => {
            if (data && data.error) {
                setValues({ ...values, error: data.error, loading: false });
            } else {
                authenticate(data, () => {
                    
                        console.log("00");
                        navigate('/user');
                    
                });
            }
        });
    };






    const handleChange = name => e => {
        setValues({ ...values, error: false, [name]: e.target.value });
    };

    const showLoading = () => (loading ? <div className="showLoading">Loading...</div> : '');
    const showError = () => (error ? <div className="showError">{error}</div> : '');
    const showMessage = () => (message ? <div className="showMessage">{message}</div> : '');



    const signinForm = () => {
        return (

            <>

                <div className="wrapper">
                    <h1 className="heading">Hello Again !</h1>
                    <p className="paragraph">Welcome back you've <br /> been missed!</p>


                    <form onSubmit={handleSubmit}>
                        <input className="inputs" value={email} onChange={handleChange('email')} name="email" type="text" placeholder="Email" />
                        <input className="inputs" value={password} onChange={handleChange('password')} name="password" type="password" placeholder="Password" />

                        <button className="button">Sign In</button>

                        <div className="notmember">
                            Not a member ? &nbsp; <a href="/signup"> Register Now</a>
                        </div>

                        <br />


                    </form>

                </div>

            </>

        )
    }



    return (
        <>
            <Navbar />
        <div className="backImg">
            <br />
            <br />
            {showError()}
            {showLoading()}
            {showMessage()}
            {showForm && signinForm()}

            <br /><br />
        </div>
        <Footer />
        </>
    )
}

export default SigninForm
