import "./styles/NavFooter.css";
import { Link } from 'react-router-dom';
import { IoIosSearch } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { IoCartOutline } from "react-icons/io5";

const Navbar = () => {

    function disablenavbar2() {
        let x = document.getElementById("disable")
       let y= document.getElementById("line")
        if (x.style.display === "block") { x.style.display = "none" }
        else { x.style.display = "block"; }

        if (y.style.display === "none") { y.style.display = "block" }
        else { y.style.display = "none"; }
    }

    function disablenavbar() {
        document.getElementById("disable").style.display = "none";
    }

    return (
        <>
            <nav className="nav">

                <div className="span" onClick={disablenavbar2}>☰</div>

                <Link to="/"><img src='/0000.webp' className="logo"></img></Link>

                <div className='navicons' >
                    <ul id="disable2" onClick={disablenavbar}>
                        <li> <IoIosSearch size={28} /></li>
                        <li> <CiUser size={28} /></li>
                        <li> <IoCartOutline size={28} /></li>
                    </ul>
                </div>



                <ul id="disable" onClick={disablenavbar}>

                    <li id='myicon'> <IoIosSearch size={28} /></li>
                    <li id='myicon'> <CiUser size={28} /></li>
                    <li id='myicon'> <IoCartOutline size={28} /></li>

                    <li><Link to="/" >Home</Link></li>
                    <li><Link to="/shop">Shop</Link></li>


                </ul>



            </nav>

            <div className='line' id='line'></div>

        </>

    )
}


export default Navbar